.inboxPage {
    background-color: #fff;
}

.inboxCard.unreadMessage {
    background-color: #fff;
}

.inboxCard.unreadMessage + .inboxCard.unreadMessage {
    border-top: 1px solid #F1F1F1;
}

.inboxCard + .inboxCard {
    border-top: 1px solid #F1F1F1;
}

.inboxCard {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.inboxCard > div.d-flex {
    width: calc(100% - 46px - 1rem);
}

.inboxCard .brandDetails {
    width: 100%;
}

.inboxCard.selected {
    background: #f2f2f7;
}

.inboxCard:hover, .inboxCard:focus {
    background: #f2f2f7;
}

.inboxCard .w-30px {
    width: 30px;
}

.badge.rounded-pill.text-dark.bg-light {
    background: #ddd !important;
    font-weight: 400;
}

.badge.rounded-pill.text-dark.bg-success {
    background: #C9FBDA !important;
    font-weight: 400;
    color: #01591F !important;
}

.badge.rounded-pill.text-dark.bg-danger {
    background: #FFCCCC !important;
    font-weight: 400;
    color: #7B0000 !important;
}

.inboxPage .senderList {
    background: #fff;
    padding: 0 !important;
    max-height: calc(100vh - 112px);
    overflow-y: auto;
    position: relative;
}

.inboxPage .conversationDetail .chatBox {
    height: calc(100vh - 112px);
}

.inboxPage .senderList::-webkit-scrollbar-track {
    background-color: #F2F2F7;
}

.inboxPage .senderList::-webkit-scrollbar-thumb {
    border-color: #F2F2F7;
    background-color: #8b8264;
}

.inboxSearch {
    border-bottom: 1px solid #e3e3e3;
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    background: #fff;
}

.chatBox {
    position: relative;
    background-color: #fff;
    border-left: 1px solid #E3E3E3;
}

.chatBox.hide {
    display: block;
}

.chatBox .bg-gray {
    background-color: var(--lightColor)!important;
}

.chatBox .chatBody > .border-bottom.h-15px {
    border-bottom: 1px solid #D1D1D6 !important;
}

.chatHeader {
    position: relative;
    padding-top: 0.5rem;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0;
}

.chatBody {
    height: calc(100vh - 260px + 1rem);
    overflow-y: auto;
    background-color: #F2F2F7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.chatArea {
    padding: 1rem;
    padding-bottom: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    max-height: calc(100vh - 264px + 1rem);
    overflow-y: auto;
}

.chatArea::-webkit-scrollbar-track {
    background-color: #F2F2F7;
}

.chatArea::-webkit-scrollbar-thumb {
    border-color: #F2F2F7;
    background-color: #8b8264;
}

.bubbleLeft {
    background: #fff;
    border-radius: 0 13px 13px 10px;
    display: flex;
    align-items: flex-end;
    padding: 0.35rem 0.5rem 0.35rem 0.75rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin: 1rem 0;
    max-width: 80%;
    width: max-content;
    font-size: .875rem;
}

.bubbleLeft > .bubbleLeftBoxes > .bubbleLeftText {
    word-break: break-word;
}

.bubbleRight {
    display: flex;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-end;
    margin: 1rem auto;
    margin-right: 0;
    max-width: 80%;
    width: max-content;
}

.bubbleRight .bubbleRightBoxes {
    display: inline-flex;
    justify-content: flex-end;
    max-width: 100%;
}

.bubbleRight .bubbleRightText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background: var(--primaryColor);
    color: #fff;
    border-radius: 13px 0 10px 13px;
    padding: 0.35rem 0.5rem 0.35rem 0.75rem;
    font-size: .875rem;
    width: max-content;
    min-width: fit-content;
    max-width: 100%;
    word-break: break-word;
}

.bubbleRight .bubbleRightText a {
    color: var(--secondaryColor);
    text-decoration: underline;
}

.chatFooter {
    padding: 0.5rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
}

.chatFooter .alertBar {
    position: absolute;
    bottom: 1.95rem;
    margin: 0 auto;
    width: auto;
}

.chatFooter .input-group > .input-group-text {
    border-right: 1px solid var(--bs-border-color) !important;
    border-radius: 12px;
}

.chatFooter .input-group .position-relative > span {
    font-size: 1rem;
    text-align: justify;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 0.75rem 1rem;
    border-top-right-radius: 10px;
}

.grow-wrap {
    display: grid;
    width: calc(100% - 78px);
    padding-right: 1rem;
}

.grow-wrap::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
}

.grow-wrap > textarea {
    resize: none;
    overflow: overlay;
    height: auto;
}

.grow-wrap > textarea,
.grow-wrap::after {
    padding: 0.5rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
}

.socialStats {
    position: relative;
    z-index: 9;
}

.socialStats .socialDropDown {
    border-radius: var(--bs-border-radius)!important;
    padding: 0 1rem 1rem !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    position: absolute!important;
    background-color: #fff;
    right: 52px;
    margin-left: 0.5rem;
    top: 0;
}

.inboxPage .senderList {
    min-width: 440px;
    max-width: 470px;
}

.conversationDetail {
    min-width: calc(100% - 470px) !important;
    max-width: calc(100% - 440px) !important;
}

.inboxPage #imageViewer .carousel-control-next, 
.inboxPage #imageViewer .carousel-control-prev, 
.inboxPage #imageViewer .carousel-indicators {
    display: none !important;
}

.inboxPage #imageViewer .carousel-item img {
    height: calc(100vh - 240px);
}

button.messageFilterBtn::after {
    display: none;
}

.dropdown-item.active, .dropdown-item:active {
    color: var(--primaryColor);
    font-weight: 500;
    background-color: var(--secondaryColor);
}

@media only screen and (min-width: 768px) {
    .inboxSearch > .input-group * {
        border: 0 !important;
    }
    .inboxSearch > .input-group > .dropdown-menu {
        border: 1px solid #ddd !important;
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    .inboxPage .senderList {
        min-width: 340px;
        max-width: 470px;
    }
    .conversationDetail {
        min-width: calc(100% - 470px) !important;
        max-width: calc(100% - 340px) !important;
    }
    .inboxCard img.object-fit-cover.rounded.border.bg-white {
        height: 30px;
        width: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .inboxPage #imageViewer .carousel-inner {
        padding-top: 50px;
    }

    .border.border-start-0 {
        border-color: #ccc !important;
    }
    .inboxPage .inboxSearch {
        z-index: 1;
    }

    .inboxPage #imageViewer .carousel-item img {
        height: calc(100vh - 130px);
        object-fit: contain;
        max-width: 90%;
    }

    .inboxCard.unreadMessage {
        background-color: #F6F3F0;
    }

    .inboxPage .senderList {
        min-width: 100%;
        max-width: 100%;
    }

    .conversationDetail {
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .inboxCard img.object-fit-cover.rounded.border.bg-white {
        height: 30px;
        width: 30px;
        background: transparent !important;
        border: 0 !important;
    }

    .inboxCard.unreadMessage + .inboxCard.unreadMessage {
        border-top: 1px solid #E3E3E3;
    }

    .inboxPage .senderList {
        max-height: fit-content !important;
    }

    .inboxPage .mx-0.px-3.row {
        padding: 0 !important;
    }

    .inboxSearch {
        padding: 0 1rem 0.75rem;
        position: relative;
        border: 0;
        z-index: 0;
    }

    .chatBox.hide {
        display: none;
    }

    .chatBox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F2F2F7;
        z-index: 9;
        border: 0;
    }

    .chatHeader {
        position: fixed;
        width: 100%;
        top: 0;
        background-color: #fff;
        padding-top: 1.70rem;
        z-index: 9;
    }

    /* .chatHeader .text-truncate {
        max-width: 50%;
    } */

    .chatBody {
        position: fixed;
        top: 98px;
        height: calc(100% - 135px - 1rem);
        overflow-y: auto;
        padding: 1rem;
        padding-bottom: 100px;
        width: 100%;
        z-index: 8;
    }

    .chatArea {
        padding: 0 1rem 70px 0 !important;
        width: calc(100% - 1rem);
        max-height: 100%;
    }

    .chatFooter {
        padding: 0.5rem;
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #fff;
        z-index: 9;
    }

    .chatFooter .alertBar {
        position: absolute;
        bottom: 1rem;
        margin: 0 auto;
        width: auto;
    }

    .hidden-disabled:disabled {
        background-color: #fff !important;
    }

    .conversationListItem {
        height: calc(100vh - 195px);
        overflow-y: overlay;
        overflow-x: hidden;
    }

    .adminInbox .conversationListItem {
        height: calc(100vh - 139px);
        overflow-y: overlay;
        overflow-x: hidden;
    }

    .conversationListItem::-webkit-scrollbar {
        width: 0;
    }

    .adminInbox #topBar {
        display: flex;
        z-index: 9;
    }

    .adminInbox #topBar > .container-fluid {
        display: flex;
    }

}